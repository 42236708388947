p {
  font-family: 'Diphylleia', serif;
  color: #a7a9ac;
  font-weight: bold;
}

h1,h2{
  font-family: 'Diphylleia', serif;
  color: #a7a9ac;
  font-weight: bolder;
}

.text-size {
  font-size: larger;
}

.title-name{
  font-size: large;
  color: white;
}

.row-content {
  padding: 5px 0;
  min-height: 60px;
}

.row-content1 {
  padding: 5px 0;
  border-bottom: 1px ridge;
  border-color: black;
  min-height: 5px;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.video-responsive {
  overflow: hidden;
  padding-top:35%;
  position: relative;
  height:0%;
}

.video-responsive iframe {
  left:0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 55%;
  position:absolute;
  margin: auto;
} 

.center{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 30%;
}

.site-header {
  background-color: #552448;
  padding: 1em 0;
  
}

.site-footer {
  background-color: #2A2223;
  padding: 20px 0;
 
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}
